import pa from '@assets/quotes/pa-portrait.webp';
import louhann from '@assets/quotes/louhann-portrait.webp';
import matteo from '@assets/quotes/matteo-portrait.webp';
import alex from '@assets/quotes/alex-portrait.webp';
import nader from '@assets/quotes/nader-portrait.webp';

import { Quote } from '../Props';
import { Viewport } from '../Statics';
import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { forwardRef } from 'react';
import { ButtonIcon } from '@components/Inputs/ButtonIcon';

const MotionQuote = motion(forwardRef((props, ref) => <Quote {...props} innerRef={ref} />));

export default () => {

    const control = useAnimation();

    const [container, setContainer] = useState();
    const [active, setActive] = useState();
    const sliderRef = useRef();
    const [slider, setSlider] = useState({
        padding: 300,
        state: "start",
        x: 0
    });

    const quotesMap = [
        {
            quote: <>I strongly recommend having him on your side for any tech-savvy initiative. <br /><mark>A real wizard!!!</mark></>,
            name: 'Paul-André C.',
            job: 'Business development & Associate at Celsius Communication',
            location: 'Montréal, Canada',
            avatar: pa.src
        },
        {
            quote: <>An incredibly talented and versatile Creative.<br />He consistently demonstrates a <mark>proactive approach to problem-solving</mark>, seamlessly adapting his skills to various projects and platforms.</>,
            name: 'Louhann M.',
            job: 'Journalist & Copywriter at Grazia Magazine',
            location: 'Paris, France',
            avatar: louhann.src
        },
        {
            quote: <>With Nassim, I was able to find that <mark>perfect balance of design and functionality.</mark><br /> He was also <mark>great at coaching and collaborating</mark> with other members of the design team for the benefit of the project.</>,
            name: 'Alex B.',
            job: 'CEO & Marketing Director at MTLVibe',
            location: 'Montréal, Canada',
            avatar: alex.src
        },
        {
            quote: <>His creations are not only aesthetically captivating, they are also designed with a <mark>great attention to detail</mark> and visual coherence.</>,
            name: 'Matteo M.',
            job: 'SEM Campaign Specialist & Project Manager',
            location: 'Marseille, France',
            avatar: matteo.src
        },
        {
            quote: <>In addition to his <mark>remarkable skill set</mark>, Nassim shines as a team player, his passion for his craft <mark>igniting the collective spirit.</mark></>,
            name: 'Nader B.M.',
            job: 'Co-Founder & Digital Strategist at Redbox Media',
            location: 'Montréal, Canada',
            avatar: nader.src
        }
    ];

    const onSliderNavigation = (direction = 0) => {

        const { padding, state, x } = slider;

        if (!sliderRef.current
            || state === 'start' && direction === -1
            || state === 'end' && direction === 1
        ) { return; }


        //get next right/left position
        const { width, right, left } = sliderRef.current.getBoundingClientRect();
        const nextRightPosition = right - (direction * padding);
        const nextLeftPosition = left - (direction * padding);
        const endDistance = Math.abs(nextRightPosition - window.innerWidth);

        let newState = 'ongoing'
        let newX = x - 1 * padding * direction;

        //update future state depending on previsionned position
        if (
            nextLeftPosition >= 0
            || Math.abs(nextLeftPosition) <= padding && direction < 0
        ) { newState = 'start'; }
        if (
            nextRightPosition < window.innerWidth
            || endDistance < padding && direction > 0
        ) { newState = 'end'; }


        //adjust padding to prevent overflow
        switch (newState) {
            case 'start':
                newX = 0;
                break;

            case 'end':
                newX = -1 * Math.abs(window.innerWidth - width);
                break;
        }

        setSlider({
            ...slider,
            state: newState,
            x: newX
        });
    }


    useEffect(() => {
        if (active) {
            control.start({ y: 0, opacity: 1 });
        }
    }, [active]);

    return (
        <Viewport
            container={container}
            onEnter={() => setActive(true)}
        >
            <section ref={e => setContainer(e)} className="quotes">
                <header className='container flex f-row f-between f-center f-wrap gap-xl'>
                    <h2>We propelled projects together</h2>
                    <div className='flex f-row gap-xl'>
                        <ButtonIcon
                            icon={
                                <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.52 18.59L11.32 16.79L5.26 10.73L21 10.73L21 8.17003L5.08 8.17003L11.32 1.93003L9.52 0.130027L0.280001 9.37002L9.52 18.59Z" />
                                </svg>
                            }
                            size="large"
                            state={slider.state === "start" ? "disabled" : "default"}
                            onClick={() => onSliderNavigation(-1)}
                        />
                        <ButtonIcon
                            icon={
                                <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.48 0.409973L9.68 2.20997L15.74 8.26997H0V10.83H15.92L9.68 17.07L11.48 18.87L20.72 9.62997L11.48 0.409973Z" />
                                </svg>

                            }
                            size="large"
                            state={slider.state === "end" ? "disabled" : "default"}
                            onClick={() => onSliderNavigation(1)}
                        />
                    </div>
                </header>

                <div
                    className='quotes-slider flex f-row gap-5xl'
                    ref={sliderRef}
                    style={{ transform: `translate3d(${slider.x}px,0,0)` }}
                >
                    <AnimatePresence>
                        {quotesMap.map((quote, i) =>
                            <MotionQuote
                                key={'quote' + quote.quote + i}
                                initial={{ y: 200, opacity: 0 }}
                                animate={control}
                                transition={{ duration: 1.2, delay: (i + 1) / 10, type: 'spring' }}
                                {...quote}
                            />
                        )}
                    </AnimatePresence>
                </div>

            </section>
        </Viewport>
    );
}