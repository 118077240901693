export const ButtonIcon = ({ icon, onClick = () => 0, state = "default", size = "default" }) => (
    <div
        className="button-icon flex f-center"
        onClick={onClick}
        data-state={state}
        data-size={size}
    >
        {icon}
    </div>
);


//state !== "disabled" && onClick || void 0